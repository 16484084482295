<template>
  <div>
  <b-card-code
    no-body
  >

    <b-card-body class="pt-0 pb-0">
      <b>Please press "Refresh" to see if your test is ready</b>
    </b-card-body>

    <b-card-body>


      
      <div class="d-flex justify-content-between  flex-wrap">

        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >

          
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>

          

        </b-form-group>

        <b-form-group
          class="mr-1 mb-md-0"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            id="refreshButton"
            @click="refreshTestTable"
          >
          <feather-icon
            icon="RefreshCcwIcon"
            class="mr-50"
          />
          <span class="align-middle">Refresh</span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            id="addTextButton"
            @click="addTextsRoute"
            class="ml-1"
          >
          <feather-icon
            icon="FilePlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Create test</span>
          </b-button>

        </b-form-group>           
         
  

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 gr-filter-search"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>






      </div>
    </b-card-body>

    

    <p v-if="!seenTable" class="text-center mt-1">
                  <b-spinner
                    class="mr-1"
                    variant="info"
                  />
    </p>

    <b-table
      striped
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      v-if="seenTable"
    >

      <template #cell(short_title)="data">
        <span v-if="data.item.status == 3 && data.item.questions_count > 0" @click="routeToPassTest(data.item.uid)" class="aslink">
          <feather-icon v-if="data.item.text_type == 0" icon="FileIcon" />
          <feather-icon v-else icon="ApertureIcon" />
          {{ data.item.short_title }}
        </span>
        <span v-else-if="data.item.status == 3 && data.item.questions_count == 0" @click="showModalEmptyQuiestions(data.item.text_type)" class="aslink">
          <feather-icon v-if="data.item.text_type == 0" icon="FileIcon" />
          <feather-icon v-else icon="ApertureIcon" />
          {{ data.item.short_title }}
        </span>
        <span v-else>
          <feather-icon v-if="data.item.text_type == 0" icon="FileIcon" />
          <feather-icon v-else icon="ApertureIcon" />
          {{ data.item.short_title }}
        </span>
      </template>    

      <template #cell(created)="data">
        {{ data.item.created | formatDate }}
      </template>      

      <template #cell(algoritm.name)="data">
        {{ data.item.algoritm.name }}<span v-if="data.item.text_type == 0">, by text</span><span v-else icon="ApertureIcon" >, by subject</span>      
      </template>         

      <template #cell(chars_count)="data">
        {{ data.item.chars_count }}
      </template>
      
      
      <template #cell(updated)="data">
        <span v-if="data.item.status == 3">{{ data.item.updated | formatDate }}</span>
      </template>


      <template #cell(status)="data">
        <div class="text-center">
          <b-spinner v-if="data.item.status == 2" class="" variant="success" />
          <b-badge :variant="status[1][data.value]" v-if="data.item.status != 2">
            {{ status[0][data.value] }}
          </b-badge>
        </div>  
      </template>

      <template #cell(questions_count)="data">
        <b-badge variant="light" class="qcount">
          {{ data.item.questions_count }}
        </b-badge>
      </template>      

      <template #cell(actions)="data">
        <div>
          <b-dropdown 
           class="m-md-2" 
           variant="link" 
           no-caret
           toggle-class="text-decoration-none"
           :disabled="data.item.status != 3">
           <template 
            v-slot:button-content 
            >
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
            </template>

            <b-dropdown-item :disabled="!(data.item.status == 3 && data.item.questions_count > 0)" @click="routeToPassTest(data.item.uid)">
              <b><feather-icon icon="ListIcon" class="mr-50" size="18"/>Pass the test</b>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-item @click="showTest(data.item.id)">
              <feather-icon icon="FileTextIcon" class="mr-50" />Source text
            </b-dropdown-item>

            <!-- <b-dropdown-item :disabled="data.item.status != 3">
              <feather-icon icon="DownloadIcon" class="mr-50" />Save (*.txt)
            </b-dropdown-item>
            <b-dropdown-item :disabled="data.item.status != 3">
              <feather-icon icon="DownloadIcon" class="mr-50" />Save (*.pdf)
            </b-dropdown-item> -->
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="deleteQuizDialog(data.item.id, data.item.short_title)">
              <feather-icon icon="FileMinusIcon" class="mr-50"/>Delete test
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>      


    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0" v-if="seenTable">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>


  </b-card-code>

    <b-modal
      ref="modal-form-delete-quiz"
      centered
      ok-title="Delete the quiz"
      cancel-title="Cancel"
      title="Confirmation"
      @ok="deleteQuiz"
      ok-variant="danger"
    >
      <div class="d-block text-center">
        <h3>Do you really want to delete quizz <br>"{{quizToDeleteTitle}}"?</h3>
      </div>
    </b-modal>

    <b-modal
      ref="modal-form-empty-questions"
      centered
      ok-title="Create new quiz"
      cancel-title="Close"
      title="Warning"
      @ok="addTextsRoute"
      ok-variant="success"
    >
      <div class="d-block text-center">
        <h3>Your input is quite difficult to process. Please shorten the text by 1,5-2 times</h3>
      </div>
    </b-modal>

    <b-modal
      ref="modal-form-empty-questions-bysubject"
      centered
      ok-title="Create new quiz"
      cancel-title="Close"
      title="Warning"
      @ok="addTextsRoute"
      ok-variant="success"
    >
      <div class="d-block text-center">
        <h3>There was a processing error. Please try changing the subject</h3>
      </div>
    </b-modal>    


    <b-modal
      ref="modal-showtext"
      scrollable
      title="Source Text"
      ok-title="Close"
      ok-only
      centered
      size="lg"
    >

    <div class="d-block text-center mt-2" v-if="!sourceText"><b-spinner class="mr-1" variant="info" />Loading...</div>
    <div class="" v-html="sourceText"></div>
    </b-modal>

    <b-modal
      ref="modal-form-empty-getprem"
      centered
      ok-title="Get Premium"
      cancel-title="Close"
      title="Warning"
      @ok="premiumRoute"
      ok-variant="success"
    >
      <div class="d-block text-center">
        <h3>Removing quizzes is only available in the paid version</h3>
      </div>
    </b-modal>

</div>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, 
  BDropdownDivider, BRipple, BModal, BSpinner
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {BCardCode, BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody,
    BDropdown, BDropdownItem, BDropdownDivider, BModal, BSpinner
     
  },
  directives: {
    Ripple,
  },  
  data() {
    return {
      sourceText:'',
      userData: JSON.parse(localStorage.getItem('userData')),
      perPage: 10,
      pageOptions: [10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'status', label: 'Status', sortable: true },        
        {
          key: 'short_title', label: 'Test', sortable: true
        },
        { key: 'actions', label: 'Actions'},        
        { key: 'questions_count', label: 'Questions', sortable: true },                
        { key: 'created', label: 'Created', sortable: true },
        { key: 'chars_count', label: 'Characters', sortable: true },
        { key: 'algoritm.name', label: 'Algoritm', sortable: true },
      ],
      /* eslint-disable global-require */
      // items: [
      //   {
      //     short_title: "The pentose phosphate pathway (also called the phosphogluconate pathway and the hexose monophosphate...",
      //     created: "01.01.2022 12:12:12",
      //     updated: '',
      //     status: 2,
      //   },
      //   {
      //     short_title: "In 1948 Bernard Silver, a graduate student at Drexel Institute of Technology in Philadelphia...",
      //     created: "01.02.2022 12:12:12",
      //     updated: '',
      //     status: 1,
      //   },
      //   {
      //     short_title: "My favourite film star is David John Tennant. He was born in Scotland, Bathgate, in 1971. He grew up",
      //     created: "01.03.2022 12:12:12",
      //     updated: '',
      //     status: 3,
      //   },
      //   {
      //     short_title: "My favourite film star is David John Tennant. He was born in Scotland, Bathgate, in 1971. He grew up",
      //     created: "01.03.2022 12:12:12",
      //     updated: '',
      //     status: 3,
      //   },
      //   {
      //     short_title: "My favourite film star is David John Tennant. He was born in Scotland, Bathgate, in 1971. He grew up",
      //     created: "01.03.2022 12:12:12",
      //     updated: '',
      //     status: 3,
      //   },
      // ],
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'New', 2: 'In processing', 3: 'Ready', 4: 'Rejected',
      },
      {
        1: 'light-primary', 2: 'light-info', 3: 'light-success', 4: 'light-danger',
      }],
      seenTable: true,
      quizToDeleteTitle:'',
      quizToDeleteId: 0
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },

  created() {
    // this.loadTexts()
  },
  mounted() {
    
    this.loadTexts();
    // this.totalRows = this.items.length
    // console.log(this.totalRows)

    //add adsense
    if (!this.userData.is_premium) {
      document.head.innerHTML += "<script async src=\"https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1886774781095399\" crossorigin=\"anonymous\"><\/script>";
    }    

  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      this.$router.push({ name: 'auth-login' })      
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    addTextsRoute() {
      this.$router.replace({ name: 'addtext' })
    },
    premiumRoute() {
      this.$router.replace({ name: 'pages-profile' })
    },    
    refreshTestTable() {
      this.loadTexts()
      this.currentPage = 1  
    },
    loadTexts() {
      this.seenTable = false
      useJwt.getUserTexts({
          // src_text: this.textQuiz,
        })
        .then(response => {
          const resData = response.data
          // console.log("response.data:")
          // console.log(response.data)
          this.items = resData
          // Set the initial number of items
          this.totalRows = this.items.length
        })
        .catch(error => {
          //this.$refs.loginForm.setErrors(error.data);
          // console.log(error)
          if (error.status == 401) {
            this.logout();
          } else {          
          this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Error getting tests",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.data.email,
                },
              })      
          }
              

        }) 
        .finally(()=>{ 
          this.seenTable = true        
        });
        
      
    },
    routeToPassTest(uid) {
      //this.$router.replace({ name: 'quiz' })
      //https://stackoverflow.com/questions/70687408/changing-a-parameter-in-vue-3-router
      this.$router.replace({
        name: 'quiz-page',
        params: {
          uid: uid,
        }
      })      
    },

    deleteQuizDialog(qid, qtitle) {
      if (this.userData.is_premium) {
        this.quizToDeleteTitle = qtitle;
        this.quizToDeleteId = qid;
        this.$refs['modal-form-delete-quiz'].show();
        } else {
          this.$refs['modal-form-empty-getprem'].show();
        }
    },

    deleteQuiz() {
      this.seenTable = false
      useJwt.deleteQuiz(
          this.quizToDeleteId
        )
        .then(response => {
          // const resData = response.data
          // this.items = resData
          // this.totalRows = this.items.length
          this.loadTexts();
        })
        .catch(error => {
          if (error.status == 401) {
            this.logout();
          } else {
          this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Error deleting the quiz",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.data.email,
                },
              })      
                
          }

        }) 
        .finally(()=>{ 
          this.seenTable = true        
        });
        
      
    }, 
    
    showTest(textid) {
      this.sourceText = '';
      this.$refs['modal-showtext'].show();  
      this.getText(textid);
    },
    showModalEmptyQuiestions(text_type) {
      if (text_type == 0) {
        this.$refs['modal-form-empty-questions'].show();
      } else {
        this.$refs['modal-form-empty-questions-bysubject'].show();
      }  
    },
    
    getText(textid) {
      useJwt.getText(
         textid, 
        {
          // src_text: this.textQuiz,
        })
        .then(response => {
          const resData = response.data
          this.sourceText = resData[0].src_text.replace(/\n/g, "<br />");
          // this.sourceText = resData[0].src_text;
        })
        .catch(error => {
          if (error.status == 401) {
            this.logout();
          } else {          
          this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Error getting text",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.data.email,
                },
              })      
          }
              

        }) 
        .finally(()=>{ 
          this.seenTable = true        
        });
        
      
    },    

  },
}
</script>


<style>
  .aslink {
    cursor: pointer;
    color: #7367f0;
  }
  .gr-filter-search button {
    margin: 0;
  }

  .qcount {
    color: #6e6b7b;
  }


  .modal-dialog-scrollable.modal-dialog{
    overflow-y: initial !important
  }
  .modal-dialog-scrollable .modal-body{
      height: 450px; 
      overflow-y: auto;
  }  



</style>
